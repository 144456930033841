<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách tài khoản liên kết</h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :selectOptions="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          style-class="vgt-table"
        ></vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} tài khoản liên kết</p>

        <!-- {{ dataInvalid.length }} -->
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách tài khoản liên kết không hợp lệ</h4>

        </div>
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->
            <!-- ==============================Mã nhân viên========================= -->
            <span
              v-if="props.column.field == 'code' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Code'||!props.row.code,dataErr:!props.row.code,}"
            >
              {{ props.row.code }}
            </span>
            <span v-if="props.column.field == 'code' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Code'"
                v-model="props.row.code"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.code, 'code', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Code'"
              >{{ props.row.code }}</span>
            </span>
            <!-- ==============================Mã nhân viên========================= -->

            <!-- ==============================Tên nhân viên========================= -->

            <span
              v-if="props.column.field == 'name' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Name'||!props.row.name,dataErr:!props.row.name,}"
            >
              {{ props.row.name }}
            </span>
            <span v-if="props.column.field == 'name' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.name, 'name', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Name'"
              >{{ props.row.name }}</span>
            </span>

            <!-- ==============================Tên nhân viên========================= -->

            <!-- ================== Đơn vị trực thuộc====================== -->
            <span
              v-if="props.column.field == 'affiliatedUnit' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'AffiliatedUnit'||!props.row.affiliatedUnit,dataErr:!props.row.affiliatedUnit,}"
            >
              {{ props.row.affiliatedUnit }}
            </span>
            <span v-if="props.column.field == 'affiliatedUnit' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'AffiliatedUnit'"
                v-model="props.row.affiliatedUnit"
                :reduce="item => item.name"
                label="name"
                :options="affiliatedList"
                @input="changeCellvalue(props.row.affiliatedUnit, 'affiliatedUnit', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'AffiliatedUnit'"
              >{{ props.row.affiliatedUnit }}</span>
            </span>
            <!-- ================== Đơn vị trực thuộc====================== -->

            <!-- =====================Tên tài khoản================== -->
            <span
              v-if="props.column.field == 'account' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Account'||!props.row.account,dataErr:!props.row.account,}"
            >
              {{ props.row.account }}
            </span>
            <span v-if="props.column.field == 'account' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Account'"
                v-model="props.row.account"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.account, 'account', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Account'"
              >{{ props.row.account }}</span>
            </span>
            <!-- =====================Tên tài khoản================== -->

            <!-- ======================Kiểu người dùng===================== -->
            <span
              v-if="props.column.field == 'userType' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'UserType'||!props.row.userType,dataErr:!props.row.userType,}"
            >
              {{ props.row.userType }}
            </span>
            <span v-if="props.column.field == 'userType' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'UserType'"
                v-model="props.row.userType"
                :reduce="title => title.name"
                label="name"
                :options="useType"
                @input="changeCellvalue(props.row.userType, 'userType', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'UserType'"
              >{{ props.row.userType }}</span>
            </span>
            <!-- ======================Kiểu người dùng===================== -->

            <!-- ======================Chức vụ===================== -->

            <span
              v-if="props.column.field == 'position' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Position'||!props.row.position,dataErr:!props.row.position,}"
            >
              {{ props.row.position }}
            </span>
            <span v-if="props.column.field == 'position' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Position'"
                v-model="props.row.position"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.position, 'position', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Position'"
              >{{ props.row.position }}</span>
            </span>
            <!-- ======================Chức vụ===================== -->

            <!-- ======================Địa chỉ===================== -->
            <span
              v-if="props.column.field == 'address' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Address'||!props.row.address,dataErr:!props.row.address,}"
            >
              {{ props.row.address }}
            </span>
            <span v-if="props.column.field == 'address' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Address'"
                v-model="props.row.address"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.address, 'address', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Address'"
              >{{ props.row.address }}</span>
            </span>

            <!-- ======================Địa chỉ===================== -->

            <!-- =====================Email======================== -->

            <span
              v-if="props.column.field == 'email' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Email'||!props.row.email,dataErr:!props.row.email,}"
            >
              {{ props.row.email }}
            </span>
            <span v-if="props.column.field == 'email' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Email'"
                v-model="props.row.email"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.email, 'email', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Email'"
              >{{ props.row.email }}</span>
            </span>

            <!-- =====================Email======================== -->

            <!-- =====================Mật khẩu====================== -->

            <span
              v-if="props.column.field == 'password' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Password'||!props.row.password,dataErr:!props.row.password,}"
            >
              {{ props.row.password }}
            </span>
            <span v-if="props.column.field == 'password' && editvalueErr === true ">

              <input
                v-if="props.row.messErr[0].location === 'Password'"
                v-model="props.row.password"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.password, 'password', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Password'"
              >{{ props.row.password }}</span>
            </span>
            <!-- =====================Mật khẩu====================== -->

          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} tài khoản liên kết</p>
      </div>
      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addClassifyHealthy()"
          >
            Thêm loại chấn thương
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VSelect,
    VueGoodTable,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      dataInvalid: [],
      dataNoInvalid: [],
      columns: [
        {
          label: 'MÃ NHÂN VIÊN',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN NHÂN VIÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'TÊN TÀI KHOẢN',
          field: 'account',
          sortable: false,
        },
        {
          label: 'MẬT KHẨU',
          field: 'password',
          sortable: false,
        },
        {
          label: 'EMAIL',
          field: 'email',
          sortable: false,
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'CHỨC VỤ',
          field: 'position',
          sortable: false,
        },
        {
          label: 'ĐƠN VỊ TRỰC THUỘC',
          field: 'affiliatedUnit',
          sortable: false,
        },
        {
          label: 'KIỂU NGƯỜI DÙNG',
          field: 'userType',
          sortable: false,
        },

      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'MÃ NHÂN VIÊN',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN NHÂN VIÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'TÊN TÀI KHOẢN',
          field: 'account',
          sortable: false,
        },
        {
          label: 'MẬT KHẨU',
          field: 'password',
          sortable: false,
        },
        {
          label: 'EMAIL',
          field: 'email',
          sortable: false,
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'CHỨC VỤ',
          field: 'position',
          sortable: false,
        },
        {
          label: 'ĐƠN VỊ TRỰC THUỘC',
          field: 'affiliatedUnit',
          sortable: false,
        },
        {
          label: 'KIỂU NGƯỜI DÙNG',
          field: 'userType',
          sortable: false,
        },
      ],
      affiliatedList: [],
      useType: [],
      editvalueErr: false, // bật tắt chỉnh sửa
    }
  },
  computed: {
    ...mapGetters('miningAccount', ['validWork', 'validNoWork', 'dataTooltip']),
  },
  created() {
    this.dataInvalid = this.validWork
    this.dataNoInvalid = this.validNoWork
    this.checkErrorUser(this.dataTooltip)
    this.fetchCombobox()
    this.fetchComboboxUseType()
  },
  methods: {
    ...mapActions('miningAccount', ['getApiExcel']),

    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    // nút check
    async CheckdataNoInvalid() {
      const model = {
        listExcel: this.dataNoInvalid,
        isValidate: true,
      }
      this.$showAllPageLoading()
      const valuedata = await this.getApiExcel(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
      this.$hideAllPageLoading()
    },
    // nút hủy bỏ
    close() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'mining-account' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addClassifyHealthy() {
      const model = {
        listExcel: this.dataInvalid,
        isValidate: false,
      }
      this.$showAllPageLoading()
      this.getApiExcel(model).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.dataInvalid = []
          if (this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'mining-account' })
          }
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },
    async fetchCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_AFFILIATE)
      this.affiliatedList = data.data
    },
    async fetchComboboxUseType() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_TYPE)
      this.useType = data.data
    },
  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
</style>
